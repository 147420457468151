var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"view-recommendations"},[(!_vm.headerContent)?_c('div',{staticClass:"header-spacing"}):_c('page-header',{attrs:{"image":_vm.headerImage,"lighter":""}},[_c('h5',[_vm._v(_vm._s(_vm.headerTags))]),_c('router-link',{staticClass:"text-light text-decoration-none",attrs:{"to":{
      name: 'content',
      params: {
        id: _vm.headerContent.id,
        slug: _vm.headerContent.slug
      }
    }}},[_c('h1',[_vm._v(_vm._s(_vm.headerContent.title))])]),_c('p',[_c('span',[_vm._v("Categoria: "),_c('strong',[_vm._v(_vm._s(_vm.headerCategory))])])]),_c('div',{staticClass:"header-buttons d-flex mb-3"},[_c('watch-button',{staticClass:"mr-2",attrs:{"content":_vm.headerContent}}),_c('div',{staticClass:"mr-2"},[_c('big-list-button',{attrs:{"content":_vm.headerContent}})],1)],1)],1),_c('season-button',{attrs:{"content":_vm.headerContent}}),_c('div',{staticClass:"container-fluid"},[_c('div',{staticClass:"container"},[_c('h4',{staticClass:"mb-0 mb-lg-1"},[_vm._v("Recomendados para você")]),_c('action-dispatcher',{attrs:{"action":"contents/getRecommendations"}},[_c('div',{staticClass:"row"},_vm._l((_vm.recommendations),function(item,index){return _c('div',{key:index,staticClass:"col-xl-1-5 col-lg-3 col-md-4 col-6"},[_c('carousel-media',{attrs:{"item":item}})],1)}),0)])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }