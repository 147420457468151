<template>
  <div class="view-recommendations">
    <div v-if="!headerContent" class="header-spacing"></div>

    <page-header v-else :image="headerImage" lighter>
      <h5>{{ headerTags }}</h5>
      <router-link :to="{
        name: 'content',
        params: {
          id: headerContent.id,
          slug: headerContent.slug
        }
      }" class="text-light text-decoration-none">
        <h1>{{ headerContent.title }}</h1>
      </router-link>
      <p>
        <span>Categoria: <strong>{{ headerCategory }}</strong></span>
      </p>

      <div class="header-buttons d-flex mb-3">
        <watch-button class="mr-2" :content="headerContent" />
        <div class="mr-2">
          <big-list-button :content="headerContent" />
        </div>
      </div>
    </page-header>

    <season-button :content="headerContent" />

    <div class="container-fluid">
      <div class="container">
        <h4 class="mb-0 mb-lg-1">Recomendados para você</h4>

        <action-dispatcher action="contents/getRecommendations">
          <div class="row">
            <div class="col-xl-1-5 col-lg-3 col-md-4 col-6" v-for="(item, index) in recommendations" :key="index">
              <carousel-media :item="item" />
            </div>
          </div>
        </action-dispatcher>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from '@/components/PageHeader'
import CarouselMedia from '@/components/CarouselMedia'
import SeasonButton from '@/components/SeasonButton'
import { mapState } from 'vuex'
import get from 'lodash/get'

export default {
  name: 'recommendations',
  components: {
    CarouselMedia,
    PageHeader,
    SeasonButton
  },
  computed: {
    ...mapState('contents', ['recommendations']),
    headerContent () {
      return get(this.recommendations, '[0]', null)
    },
    headerImage () {
      return get(get(this.headerContent, 'thumbnails', []).find(item => item.device === 'web'), 'file', '')
    },
    headerCategory () {
      return get(this.headerContent, 'categories[0].name', '')
    },
    headerTags () {
      return get(this.headerContent, 'tags', []).map(item => `#${item.name}`).join(' ')
    }
  }
}
</script>

<style lang="scss" scoped>
.view-recommendations {
  .header-buttons {
    ::v-deep .like-button {
      font-size: 28px !important;
    }
  }

  h4 {
    @media screen and (max-width: 767px) {
      font-size: 16px;
    }
  }
}
</style>
